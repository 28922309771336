import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import ErrorElement from "./components/common/error-element";
import { Student, Admin } from "./App";
import AddCategory from "./components/pages/categories/add-category";
import EditCategory from "./components/pages/categories/edit-category";
import ListCategories from "./components/pages/categories/list-category";
import DashHome from "components/pages/student-dash/dash-home";
import StripeContainer from "components/pages/payment-stripe/stripe-container";

const LazyListEvents = lazy(
  () => import("./components/pages/event-pages/list-event")
);

const LazyInstructorsListing = lazy(
  () => import("./components/pages/instructors/list-all-instructors")
);

const LazyStudentDash = lazy(
  () => import("./components/pages/student-dash/user-dashboard")
);
const LazyAboutResearchLabIndex = lazy(
  () => import("./components/pages/admin/view-about-index")
);

const LazyInstructorIndex = lazy(
  () => import("./components/pages/instructor-management/view-speakers-index")
);

const LazyStudents = lazy(
  () => import("./components/pages/student-management/students-tab")
);

const LazyCategories = lazy(
  () => import("./components/pages/categories/category-page")
);

export const LazyViewCourses = lazy(
  () => import("./components/pages/course-pages/list-course")
);
//ListCourse
export const LazyViewEvent = lazy(
  () => import("./components/pages/event-pages/view-event")
);
export const LazyViewCourse = lazy(
  () => import("./components/pages/course-pages/view-course")
);
export const LazyViewEventRequests = lazy(
  () => import("./components/pages/event-pages/list-admin-pending-events")
);

export const LazyListAdminEvents = lazy(
  () => import("./components/pages/event-pages/view-events-index")
);
export const LazyListAdminCourses = lazy(
  () => import("./components/pages/course-pages/view-courses-index")
);

const LazyWatchEvent = lazy(
  () => import("./components/pages/event-pages/view-event")
);

const LazyAddEvent = lazy(
  () => import("./components/pages/add-event/add-event-form")
);

const LazyAddCourse = lazy(
  () => import("./components/pages/add-course/add-course-form")
);
const LazyEditCourse = lazy(
  () => import("./components/pages/add-course/edit-course")
);

const LazyAddLesson = lazy(
  () => import("./components/pages/add-lesson/add-lessons-form")
);
const LazyViewLessons = lazy(
  () => import("./components/pages/add-lesson/view-lessons")
);

const LazyWatchLesson = lazy(
  () => import("./components/pages/course-pages/watch-lesson")
);

const LazyListCourseInstructors = lazy(
  () => import("./components/pages/add-lesson/list-course-for-speakers")
);

const LazyEditLesson = lazy(
  () => import("./components/pages/add-lesson/edit-lesson")
);

const LazyEditEvent = lazy(
  () => import("./components/pages/add-event/edit-event")
);

const LazyMyStudents = lazy(
  () => import("./components/pages/instructors/my-students")
);

const LazyInstructorProfile = lazy(
  () => import("./components/pages/instructors/insructor-profile")
);
const LazyInstructorUpdateProfile = lazy(
  () => import("./components/pages/instructors/instructor-update-profile")
);

const LazyViewInstructor = lazy(
  () => import("./components/pages/instructors/view-instructor")
);

const LazyStudentProfile = lazy(
  () => import("./components/pages/student-dash/my-profile")
);

const LazyStudentCourses = lazy(
  () => import("./components/pages/student-dash/my-courses")
);

const LazyStudentHomePage = lazy(
  () => import("./components/pages/students/student-home-page")
);

const LazyStudentLogin = lazy(
  () => import("./components/pages/students/student-login-page")
);

const LazyStudentRegister = lazy(
  () => import("./components/pages/students/student-registration-page")
);

const LazyInstructorRegister = lazy(
  () => import("./components/pages/instructors/instructor-register-page")
);

const LazyViewBlockedInstructors = lazy(
  () => import("./components/pages/instructor-management/view-blocked-speakers")
);

const LazyCommunity = lazy(
  () => import("./components/pages/community/community-home")
);

const LazyAboutUs = lazy(() => import("./components/pages/about/about-us"));

const LazyContactPage = lazy(
  () => import("./components/pages/contact/contact-us")
);

// sections
const LazyListAdminCourseSections = lazy(
  () => import("./components/pages/course-section/list-course-sections")
);

const LazyAddSection = lazy(
  () => import("./components/pages/course-section/add-section")
);
const LazyEditSection = lazy(
  () => import("./components/pages/course-section/edit-section")
);

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <Student />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyStudentHomePage />
          </Suspense>
        ),
      },
      {
        path: "/events",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyListEvents />
          </Suspense>
        ),
      },
      {
        path: "/events/:eventId",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyViewEvent />
          </Suspense>
        ),
      },

      {
        path: "/speakers",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyInstructorsListing />
          </Suspense>
        ),
      },
      {
        path: "/speakers/register",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyInstructorRegister />
          </Suspense>
        ),
      },

      {
        path: "/speakers/:tutorId",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyViewInstructor />
          </Suspense>
        ),
      },
      {
        path: "/community",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyCommunity />
          </Suspense>
        ),
      },
      {
        path: "/about",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyAboutUs />
          </Suspense>
        ),
      },
      {
        path: "/contact",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyContactPage />
          </Suspense>
        ),
      },

      // courses
      {
        path: "/courses",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyViewCourses />
          </Suspense>
        ),
      },
      {
        path: "/courses/:courseId",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyViewCourse />
          </Suspense>
        ),
      },
      {
        path: "/section/:sectionId/watch-lessons/:lessonId",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyWatchLesson />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <LazyStudentDash />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyStudentCourses />
          </Suspense>
        ),
      },
      {
        path: "my-courses",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyStudentCourses />
          </Suspense>
        ),
      },
      {
        path: "my-profile",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyStudentProfile />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "courses/:courseId/payment",
    element: <StripeContainer />,
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <LazyStudentLogin />
      </Suspense>
    ),
  },
  {
    path: "/register",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <LazyStudentRegister />
      </Suspense>
    ),
  },
  {
    path: "admin",
    element: <Admin />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<div>loading...</div>}>
            <LazyInstructorIndex />
          </Suspense>
        ),
      },
      {
        path: "about",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyAboutResearchLabIndex />
          </Suspense>
        ),
      },
      {
        path: "speakers",
        element: (
          <Suspense fallback={<div>loading...</div>}>
            <LazyInstructorIndex />
          </Suspense>
        ),
        children: [
          {
            path: "blocked",
            element: (
              <Suspense fallback={<div>loading...</div>}>
                <LazyViewBlockedInstructors />
              </Suspense>
            ),
          },
          {
            path: "add-event",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <LazyAddEvent />
              </Suspense>
            ),
          },
          {
            path: ":tutorId",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <LazyInstructorUpdateProfile />
              </Suspense>
            ),
          },
          {
            path: "view-event",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <LazyListCourseInstructors />
              </Suspense>
            ),
          },
          {
            path: "edit-event/:eventId",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <LazyEditEvent />
              </Suspense>
            ),
          },
          {
            path: "view-profile",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <LazyInstructorProfile />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "students",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyStudents />
          </Suspense>
        ),
      },
      {
        path: "events",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyListAdminEvents />
          </Suspense>
        ),
        children: [
          {
            path: "requests",
            element: (
              <Suspense fallback={<div>loading...</div>}>
                <LazyViewEventRequests />
              </Suspense>
            ),
          },
          {
            path: ":eventId",
            element: (
              <Suspense fallback={<div>loading...</div>}>
                <LazyViewEvent />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "courses",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyListAdminCourses />
          </Suspense>
        ),
        children: [
          {
            path: "add-course",
            element: (
              <Suspense fallback={<div>loading...</div>}>
                <LazyAddCourse />
              </Suspense>
            ),
          },
          {
            path: "edit-course/:courseId",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <LazyEditCourse />
              </Suspense>
            ),
          },
          {
            path: "add-lesson/:courseId/:sectionId",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <LazyAddLesson />
              </Suspense>
            ),
          },
          // {
          //   path: "view-lessons/:courseId",
          //   element: (
          //     <Suspense fallback={<div>Loading...</div>}>
          //       <LazyViewLesson />
          //     </Suspense>
          //   ),
          // },
          {
            path: "view-students",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <LazyMyStudents />
              </Suspense>
            ),
          },
          {
            path: "view-lessons/:sectionId/edit-lesson/:lessonId",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <LazyEditLesson />
              </Suspense>
            ),
          },
          {
            path: "view-students",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <LazyMyStudents />
              </Suspense>
            ),
          },
          {
            path: "view-course-sections/:courseId",
            element: (
              <Suspense fallback={<div>loading...</div>}>
                <LazyListAdminCourseSections />
              </Suspense>
            ),
          },
          {
            path: "view-course-sections/:courseId/add-section",
            element: (
              <Suspense fallback={<div>loading...</div>}>
                <LazyAddSection />
              </Suspense>
            ),
          },
          {
            path: "view-course-sections/:courseId/edit-section/:sectionId",
            element: (
              <Suspense fallback={<div>loading...</div>}>
                <LazyEditSection />
              </Suspense>
            ),
          },

          {
            path: ":courseId",
            element: (
              <Suspense fallback={<div>loading...</div>}>
                <LazyViewCourse />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "categories",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyCategories />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: <ListCategories />,
          },
          {
            path: "add-category",
            element: <AddCategory />,
          },
          {
            path: "edit-category/:categoryId",
            element: <EditCategory />,
          },
        ],
      },
    ],
  },
]);
export default AppRouter;
