import React, { useEffect, useState } from "react";
import {
  Card,
  List,
  Button,
  Drawer,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, PowerIcon, TagIcon, UserGroupIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  AcademicCapIcon,
  Cog6ToothIcon,
  Square3Stack3DIcon,PlayIcon
} from "@heroicons/react/24/outline";
import { APP_LOGO } from "../../../constants/common";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoutConfirmationModal from "components/elements/student-logout-modal";
import { useDispatch } from "react-redux";
import { clearToken } from "redux/reducers/authSlice";
import { clearDetails as studentClearDetails } from "redux/reducers/studentSlice";
import { clearCourse } from "redux/reducers/courseSlice";

export function AdminSideNav(props: any) {
  const { isSideBarOpen, setIsSideBarOpen } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [selected, setSelected] = useState("Speakers"); // Set the default selected item here
  
  const navItems = [
    // { icon: PresentationChartBarIcon, label: "Dashboard", path: "" },
    { icon: AcademicCapIcon, label: "Speakers", path: "speakers" },
    { icon: UserGroupIcon, label: "Students", path: "students" },
    { icon: TagIcon, label: "Categories", path: "categories" },
    { icon: Square3Stack3DIcon, label: "Courses", path: "courses" },
    { icon: PlayIcon, label: "Events", path: "events" },
    { icon: Cog6ToothIcon, label: "About", path: "about" },
    { icon: PowerIcon, label: "Log Out", path: "" },
  ];
  const handleLogout = () => {
    setTimeout(() => {
      dispatch(clearToken());
      dispatch(studentClearDetails())
      dispatch(clearCourse())
      navigate("/");
    }, 2000);
  };
  const handleNavItemSelect = (val: string) => {
    if (val !== "Log Out") setSelected(val);
    else setLogoutOpen(true);
  };
  const [matches, setMatches] = useState(
    typeof window !== "undefined" &&
      window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    if (typeof window !== "undefined")
      window
        .matchMedia("(min-width: 768px)")
        .addEventListener("change", (e) => setMatches(e.matches));
        
    setSelected(() => {
      let path = navItems.filter((x) => {
        if (location.pathname.includes(x.path)) return x;
      });
      return path?.[0].label;
    });
  }, []);

  return (
    <div className="drawer-transparent-custom">
      {!matches && !isSideBarOpen && (
        <div className="flex absolute top-6">
          <IconButton
            variant="text"
            size="lg"
            onClick={() => {
              setIsSideBarOpen(!isSideBarOpen);
            }}
            placeholder={undefined}
            className=""
          >
            {isSideBarOpen ? (
              <XMarkIcon className="h-8 w-8 stroke-2" />
            ) : (
              <Bars3Icon className="h-8 w-8 stroke-2" />
            )}
          </IconButton>
        </div>
      )}
      <Drawer
        open={isSideBarOpen || matches}
        onClose={() => {
          setIsSideBarOpen(!isSideBarOpen);
        }}
        placeholder={undefined}
        className="rounded-none bg-inherit"
        style={{
          zIndex: "99999 !important",
        }}
      >
        {!matches && (
          <IconButton
            variant="text"
            size="lg"
            onClick={() => {
              setIsSideBarOpen(!isSideBarOpen);
            }}
            placeholder={undefined}
          >
            {isSideBarOpen ? (
              <XMarkIcon className="h-8 w-8 stroke-2" />
            ) : (
              <Bars3Icon className="h-8 w-8 stroke-2" />
            )}
          </IconButton>
        )}
        <Card
          className="h-[calc(100vh-1rem)] w-full max-w-[20rem] p-4 m-3 shadow-xl shadow-blue-gray-900/5"
          placeholder={undefined}
        >
          <div className="mb-2 flex items-center gap-4 p-4">
            <img src={APP_LOGO} alt="brand" className="w-full" />
          </div>
          <List placeholder={undefined}>
            {navItems.map((item, index) => (
              <Link to={`/admin/${item.path}`} key={index}>
                {selected === item.label ? (
                  <Button
                    fullWidth
                    className="p-1 text-xs m-1 bg-orangeCustom"
                    size="sm"
                    placeholder={undefined}
                  >
                    <li
                      className={`cursor-pointer flex items-center gap-2 p-2.5 rounded-lg`}
                      onClick={() => {
                        handleNavItemSelect(item.label);
                      }}
                    >
                      {React.createElement(item.icon, { className: "h-6 w-6" })}
                      {item.label}
                    </li>
                  </Button>
                ) : (
                  <li
                    className={`cursor-pointer font-semibold text-xs uppercase flex items-center gap-2 p-2.5 m-1 rounded-lg`}
                    onClick={() => {
                      handleNavItemSelect(item.label);
                    }}
                  >
                    {React.createElement(item.icon, { className: "h-6 w-6" })}
                    {item.label}
                  </li>
                )}
              </Link>
            ))}
          </List>
          {logoutOpen && (
            <LogoutConfirmationModal
              open={logoutOpen}
              setOpen={function (value: boolean): void {
                setLogoutOpen(value);
              }}
              onConfirm={handleLogout}
            />
          )}
        </Card>
      </Drawer>
    </div>
  );
}
