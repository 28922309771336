import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { FaSpinner } from "react-icons/fa";

interface ModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
  confirmButtonLabel?: string;
  variant?: "success" | "warning" | "error";
}

export default function LogoutConfirmationModal({
  open,
  setOpen,
  onConfirm,
  title,
  message,
  confirmButtonLabel,
  variant,
}: ModalProps) {
  const cancelButtonRef = useRef(null);
  const [logoutClicked, setLogoutClicked] = useState<boolean>(false);

  const confirmLogout = () => {
    setLogoutClicked(true);
    onConfirm();
    // setOpen(false);
  };
  useEffect(() => {
    return () => setLogoutClicked(false);
  }, []);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed backdrop-blur-sm inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg z-50">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    {variant ? (
                      variant === "warning" ? (
                        <ExclamationCircleIcon
                          className="h-6 w-6 text-yellow-600"
                          aria-hidden="true"
                        />
                      ) : variant === "success" ? (
                        <CheckCircleIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      ) : (
                        <ExclamationCircleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {title ?? "Logout"}
                    </Dialog.Title>
                    <p className="mt-2 text-sm text-gray-800">
                      {message ?? "Are you sure you want to logout?"}
                    </p>
                  </div>
                </div>
              </div>
              <div className=" px-4 pb-5 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  onClick={confirmLogout}
                >
                  {logoutClicked ? (
                    <FaSpinner className="animate-spin ml-1" size={20} />
                  ) : (
                    confirmButtonLabel ?? "Ok"
                  )}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => {
                    setOpen(false);
                    setLogoutClicked(false);
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
