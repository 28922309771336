import { PaymentIntent } from "@stripe/stripe-js";
import CONFIG_KEYS from "../../../config";
import axiosInstance from "../../middlewares/interceptor";
import api from "../../middlewares/protected-interceptor";

export const addCourseService = async (
  endpoint: string,
  eventInfo: FormData
) => {
  const response = await api.post(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}`,
    eventInfo
  );
  return response;
};
export const editCourseService = async (
  endpoint: string,
  courseId: string,
  eventInfo: FormData
) => {
  const response = await api.put(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${courseId}`,
    eventInfo
  );
  return response;
};
export const removeCourseService = async (
  endpoint: string,
  courseId: string
) => {
  const response = await api.delete(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${courseId}`
  );
  return response;
};
export const removeCourseImageService = async (
  endpoint: string,
  eventId: string,
  imageId: string
) => {
  const response = await api.put(`${CONFIG_KEYS.API_BASE_URL}/${endpoint}`, {
    imageId,
    eventId: eventId,
  });
  return response;
};
export const getCoursesByInstructorService = async (endpoint: string) => {
  const response = await api.get(`${CONFIG_KEYS.API_BASE_URL}/${endpoint}`);
  return response.data;
};

export const getAllCoursesService = async (endpoint: string) => {
  const response = await axiosInstance.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}`
  );
  return response;
};
export const getOngoingCoursesService = async (endpoint: string) => {
  const response = await axiosInstance.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}`
  );
  return response;
};
export const acceptPendingCourseService = async (
  endpoint: string,
  eventId: string
) => {
  const response = await axiosInstance.post(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${eventId}`
  );
  return response;
};

export const getIndividualCourseService = async (
  endpoint: string,
  courseId: string
) => {
  const response = await axiosInstance.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${courseId}`
  );
  return response;
};
export const enrollStudentService = async (
  endpoint: string,
  eventId: string,
  paymentInfo?: PaymentIntent
) => {
  const response = await api.post(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${eventId}`,
    paymentInfo
  );
  return response.data;
};
export const getRecommendedCoursesService = async (endpoint: string) => {
  const response = await api.get(`${CONFIG_KEYS.API_BASE_URL}/${endpoint}`);
  return response.data;
};
export const getTrendingCoursesService = async (endpoint: string) => {
  const response = await axiosInstance.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}`
  );
  return response.data;
};
export const getCourseByStudentService = async (endpoint: string) => {
  const response = await api.get(`${CONFIG_KEYS.API_BASE_URL}/${endpoint}`);
  return response.data;
};
export const searchCourseService = async (
  endpoint: string,
  searchQuery: string,
  filterQuery: string
) => {
  const response = await api.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}?search=${searchQuery}&filter=${filterQuery}`
  );
  return response.data;
};

// Section info
export const addCourseSectionService = async (
  endpoint: string,
  sectionInfo: FormData,
  courseId: string
) => {
  const response = await api.post(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${courseId}`,
    sectionInfo
  );
  return response;
};
export const editCourseSectionService = async (
  endpoint: string,
  sectionId: string,
  sectionInfo: FormData
) => {
  const response = await api.put(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${sectionId}`,
    sectionInfo
  );
  return response;
};
export const getAllCourseSectionsService = async (
  endpoint: string,
  courseId: string
) => {
  const response = await axiosInstance.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${courseId}`
  );
  return response;
};
export const removeCourseSectionService = async (
  endpoint: string,
  sectionId: string
) => {
  const response = await api.delete(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${sectionId}`
  );
  return response;
};
export const getSectionDetailsService = async (
  endpoint: string,
  sectionId: string
) => {
  const response = await axiosInstance.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${sectionId}`
  );
  return response;
};
