import { useState, useEffect } from "react";
import { debounce } from "lodash";

type SearchableItem = Record<string, any>; // Define the type of the searchable item here

const useSearch = <T extends SearchableItem>(
  data: T[],
  query: string,
  searchKey: keyof T
): T[] => {
  const [searchResults, setSearchResults] = useState<T[]>([]);

  useEffect(() => {
    const delay = 300; 
    const debounceSearch = debounce(() => {
      if (query.trim() !== "") {
        const regex = new RegExp(query, "i");
        const filteredResults = data.filter((item) => {
          return regex.test(item[searchKey].toString());
        });
        setSearchResults(filteredResults);
      } else {
        setSearchResults([]);
      }
    }, delay);

    debounceSearch();

    return () => {
      debounceSearch.cancel();
    };
  }, [data, query, searchKey]);

  return searchResults;
};

export default useSearch;
