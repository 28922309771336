import React, { useEffect, useState } from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getInstructorDetails } from "./api/endpoints/instructor";
import SessionExpired from "./components/common/session-expired-modal";
import YouAreOffline from "./components/common/you-are-offline";
import AdminLoginPage from "./components/pages/admin/admin-login-page";
import { AdminSideNav } from "./components/pages/admin/admin-side-nav";
import InstructorHeader from "./components/pages/instructors/instructor-header";
import InstructorLoginPage from "./components/pages/instructors/instructor-login-page";
import InstructorSideNav from "./components/pages/instructors/instructor-side-nav";
import StudentFooter from "./components/partials/student-footer";
import StudentHeader from "./components/partials/student-header";
import useIsOnline from "./hooks/useOnline";
import { selectIsLoggedIn, selectUserType } from "./redux/reducers/authSlice";
import { selectIsFooterVisible } from "./redux/reducers/helperSlice";
import { setDetails } from "./redux/reducers/speakerSlice";
import { fetchStudentData } from "./redux/reducers/studentSlice";

export const Student: React.FC = () => {
  const isOnline = useIsOnline();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const footerVisible = useSelector(selectIsFooterVisible);
  const dispatch: any = useDispatch();
  const isHeaderVisible = true;
  const user = useSelector(selectUserType);
  const [showSessionExpired, setShowSessionExpired] = useState(false);

  const handleCloseSessionExpired = () => {
    setShowSessionExpired(false);
  };

  // Listen for the "sessionExpired" event from the interceptor
  useEffect(() => {
    const handleSessionExpired = () => {
      setShowSessionExpired(true);
    };

    window.addEventListener("sessionExpired", handleSessionExpired);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("sessionExpired", handleSessionExpired);
    };
  }, []);

  const headerClassName = `bg-gray-100 ${isHeaderVisible
    ? "opacity-100 transition-opacity duration-500 "
    : "opacity-0 "
    }`;

  useEffect(() => {
    if (isLoggedIn && user === "student") {
      dispatch(fetchStudentData());
    }
  }, [dispatch]);

  return (
    <>
      {showSessionExpired && (
        <SessionExpired
          show={showSessionExpired}
          onClose={handleCloseSessionExpired}
        />
      )}
      {isOnline ? (
        <div className="bg-white font-sans">
          <div className={`${headerClassName}`}>
            <StudentHeader />
          </div>
          <Outlet />
          {footerVisible && <StudentFooter />}
        </div>
      ) : (
        <YouAreOffline />
      )}
    </>
  );
};

export const Instructor: React.FC = () => {
  const isOnline = useIsOnline();
  const user = useSelector(selectUserType);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const fetchInstructor = async () => {
    try {
      const response = await getInstructorDetails();
      dispatch(setDetails({ details: response.data }));
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    fetchInstructor();
  }, []);

  return (
    <>
      {isOnline ? (
        isLoggedIn && user === "instructor" ? (
          <>
            <div className="fixed inset-x-0 top-0 flex flex-col font-sans">
              <InstructorHeader />
              <div className="flex flex-1">
                <div className="w-64 h-screen overflow-y-auto">
                  <InstructorSideNav />
                </div>
                <div className="flex  flex-col flex-1">
                  <div className="p-4 bg-customOrangeShade overflow-y-scroll h-screen">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <InstructorLoginPage />
          </div>
        )
      ) : (
        <YouAreOffline />
      )}
    </>
  );
};

export const Admin: React.FC = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const isAdminLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUserType);
  const isOnline = useIsOnline();
  const [matches, setMatches] = useState(
    typeof window !== "undefined" &&
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    if (typeof window !== "undefined")
      window
        .matchMedia("(min-width: 768px)")
        .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <>
      {isOnline ? (
        isAdminLoggedIn && user?.includes("admin") ? (
          <div className="bg-gray-100 items-center  flex justify-center font-sans overflow-y-hidden">
            <div className={matches ? "w-80" : ""}>
              <AdminSideNav
                isSideBarOpen={isSideBarOpen}
                setIsSideBarOpen={setIsSideBarOpen}
              />
            </div>
            <div className="flex-1 pl-4 h-screen max-h-full overflow-y-scroll mt-5">
              {/* Use 'h-screen' and 'max-h-full' to allow the container to take the full screen height */}
              <Outlet />
            </div>
          </div>
        ) : (
          <div className="bg-gray-100">
            <AdminLoginPage />
          </div>
        )
      ) : (
        <YouAreOffline />
      )}
    </>
  );
};
