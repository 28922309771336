import { PaymentIntent } from "@stripe/stripe-js";
import CONFIG_KEYS from "../../../config";
import axiosInstance from "../../middlewares/interceptor";
import api from "../../middlewares/protected-interceptor";

export const addEventService = async (
  endpoint: string,
  eventInfo: FormData
) => {
  const response = await api.post(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}`,
    eventInfo
  );
  return response;
};

export const editEventService = async (
  endpoint: string,
  courseId: string,
  eventInfo: FormData
) => {
  const response = await api.put(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${courseId}`,
    eventInfo
  );
  return response;
};
export const removeEventImageService = async (
  endpoint: string,
  eventId: string,
  imageId: string
) => {
  const response = await api.put(`${CONFIG_KEYS.API_BASE_URL}/${endpoint}`, {
    imageId,
    eventId: eventId,
  });
  return response;
};

export const getEventsByInstructorService = async (endpoint: string) => {
  const response = await api.get(`${CONFIG_KEYS.API_BASE_URL}/${endpoint}`);
  return response.data;
};

export const getAllEventsService = async (endpoint: string) => {
  const response = await axiosInstance.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}`
  );
  return response;
};
export const getOngoingEventsService = async (endpoint: string) => {
  const response = await axiosInstance.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}`
  );
  return response;
};
export const acceptPendingEventService = async (
  endpoint: string,
  eventId: string
) => {
  const response = await axiosInstance.post(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${eventId}`
  );
  return response;
};

export const getIndividualEventService = async (
  endpoint: string,
  eventId: string
) => {
  const response = await axiosInstance.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${eventId}`
  );
  return response;
};

export const enrollStudentService = async (
  endpoint: string,
  eventId: string,
  paymentInfo?: PaymentIntent
) => {
  const response = await api.post(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}/${eventId}`,
    paymentInfo
  );
  return response.data;
};

export const getRecommendedEventsService = async (endpoint: string) => {
  const response = await api.get(`${CONFIG_KEYS.API_BASE_URL}/${endpoint}`);
  return response.data;
};

export const getTrendingEventsService = async (endpoint: string) => {
  const response = await axiosInstance.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}`
  );
  return response.data;
};

export const getEventByStudentService = async (endpoint: string) => {
  const response = await api.get(`${CONFIG_KEYS.API_BASE_URL}/${endpoint}`);
  return response.data;
};

export const searchEventService = async (
  endpoint: string,
  searchQuery: string,
  filterQuery: string
) => {
  const response = await api.get(
    `${CONFIG_KEYS.API_BASE_URL}/${endpoint}?search=${searchQuery}&filter=${filterQuery}`
  );
  return response.data;
};
