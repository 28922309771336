const Container = ({
  children,
  isCenterable,
}: {
  children: React.ReactNode;
  isCenterable?: boolean;
}) => {
  return (
    <div
      className={` ${
        (isCenterable || isCenterable === undefined) &&
        "lg:flex justify-center items-center"
      }`}
    >
      <div className="max-lg:px-3 max-w-[1280px] xl:px-20 lg:px-10 px-3" style={{
        display:"block",
        margin:"0 auto"
      }}>
        {children}
      </div>
    </div>
  );
};

export default Container;
