export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return formattedDate;
}

export const capitalizeFirstLetter = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export function formatToINR(number: number): string {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  return formatter.format(number);
}

export const formatTime = (milliseconds: number): string => {
  const seconds = Math.floor(milliseconds / 1000);
  const days = Math.floor(seconds / 86400); // 86400 seconds in a day
  const hours = Math.floor((seconds % 86400) / 3600); // 3600 seconds in an hour
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${days}d ${hours}h ${minutes}m ${remainingSeconds}s`;
  return formattedTime;
};

export function getDateAndTime(dateString: string) {
  const date = new Date(dateString);
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };
  return date.toLocaleString("en-US", options);
}

export function formatUrl(url: any) {
  let formatedUrl = "";
  if (url) {
    if (typeof url === "string")
      formatedUrl = url?.includes("http")
        ? url
        : `https://dev-projects-bucket.sgp1.digitaloceanspaces.com/${url}`;
    else
      formatedUrl = url.key?.includes("http")
        ? url?.key
        : `https://dev-projects-bucket.sgp1.digitaloceanspaces.com/${url?.key}`;
  }
  return formatedUrl;
}

export const checkDateStatus = (inputDate: string) => {
  let fromattedinputDate = new Date(inputDate) as any;
  const currentDate = new Date();

  const inputDateOnly = new Date(fromattedinputDate.toDateString() as any);
  const currentDateOnly = new Date(currentDate.toDateString());

  if (inputDateOnly < currentDateOnly) return "Past";
  else if (inputDateOnly > currentDateOnly) {
    const tomorrow = new Date(currentDateOnly);
    tomorrow.setDate(currentDateOnly.getDate() + 1);

    if (inputDateOnly.getTime() === tomorrow.getTime()) {
      return "Upcoming"; // tomorrow
    } else {
      return "Upcoming";
    }
  } else {
    return "Ongoing";
  }
};

export function timeDifference(targetDate: Date) {
  // Current date
  var currentDate = new Date();

  // Convert target date to milliseconds
  var targetTime = targetDate.getTime();

  // Convert current date to milliseconds
  var currentTime = currentDate.getTime();

  // Calculate the difference in milliseconds
  var difference = targetTime - currentTime;

  // Calculate days, hours, minutes, and seconds
  var days = Math.floor(difference / (1000 * 60 * 60 * 24));
  var hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((difference % (1000 * 60)) / 1000);

  // Return the result
  return {
    days: days,
    hrs: hours,
    mins: minutes,
    secs: seconds,
  };
}
