const END_POINTS = {
  LOGIN_STUDENT: "api/auth/student-login",
  REGISTER_STUDENT: "api/auth/student-register",
  GOOGLE_LOGIN_STUDENT: "api/auth/login-with-google",
  REGISTER_INSTRUCTOR: "api/auth/instructor/instructor-register",
  LOGIN_INSTRUCTOR: "api/auth/instructor/instructor-login",
  LOGIN_ADMIN: "api/auth/admin/admin-login",
  GET_INSTRUCTOR_REQUESTS: "api/instructors/view-instructor-requests",
  GET_INSTRUCTOR: "api/instructors/view-instructor",
  ACCEPT_INSTRUCTOR_REQUESTS: "api/instructors/accept-instructor-request",
  REJECT_INSTRUCTOR_REQUESTS: "api/instructors/reject-instructor-request",
  GET_INSTRUCTORS: "api/instructors/get-all-instructors",
  BLOCK_INSTRUCTORS: "api/instructors/get-all-instructors/block-instructors",
  UNBLOCK_INSTRUCTORS:
    "api/instructors/get-all-instructors/unblock-instructors",
  GET_BLOCKED_INSTRUCTORS: "api/instructors/get-blocked-instructors",
  ADD_EVENT: "api/events/speakers/add-event",
  GET_ALL_EVENTS: "api/events/get-all-events",

  COURSES: {
    ADD_COURSE: "api/courses/add-course",
    GET_COURSE: "api/courses/get-course",
    GET_ALL_COURSES: "api/courses/get-all-courses",
    GET_ALL_PENDING_COURSES: "api/courses/get-all-pending-courses",
    ACCEPT_PENDING_COURSE: "api/courses/accept-pending-course",
    ADD_LESSON: "api/courses/add-lesson",
    EDIT_LESSON: "api/courses/edit-lesson",
    DELETE_LESSON: "api/courses/remove-lesson",
    GET_LESSONS_BY_ID: "api/courses/get-lessons-by-id",
    WATCHED_COURSE_DETAILS: "api/courses/lesson/watched-lessons",
    WATCH_COURSE_LESSON: "api/courses/lesson/watched-lessons",
    STREAM_VIDEO: "api/video-streaming/stream-video",
    GET_QUIZZES_BY_LESSON: "api/courses/get-quizzes-by-lesson",
    ADD_DISCUSSION: "api/courses/lessons/add-discussion",
    GET_DISCUSSIONS_BY_LESSON: "api/courses/lessons/get-discussions-by-lesson",
    EDIT_DISCUSSION: "api/courses/lessons/edit-discussion",
    DELETE_DISCUSSION: "api/courses/lessons/delete-discussion",
    REPLY_TO_DISCUSSION: "api/courses/lessons/reply-discussion",
    GET_REPLIES_BY_DISCUSSION: "api/courses/lesson/replies-based-on-discussion",
    PAY_USING_STRIPE: "api/payments/stripe/create-payment-intent",
    GET_CONFIG: "api/payments/stripe/get-config",
    ENROLL_STUDENT: "api/courses/enroll-student",
    EDIT_COURSE: "api/courses/edit-course",
    REMOVE_COURSE: "api/courses/remove-course",
    REMOVE_COURSE_IMAGE: "api/courses/remove-course-image",
    SEARCH_COURSE: "api/courses/search-course",
    GET_RECOMMENDED_COURSES: "api/courses/get-recommended-courses",
    GET_TRENDING_COURSES: "api/courses/get-trending-courses",
    //sections
    ADD_SECTION: "api/courses/add-section",
    EDIT_SECTION: "api/courses/edit-section",
    GET_SECTION: "api/courses/get-section",
    REMOVE_SECTION: "api/courses/remove-section",
    GET_ALL_SECTIONS: "api/courses/get-all-sections",
    GET_SECTION_LESSONS: "api/courses/get-section-lessons",
    GET_COURSE_BY_STUDENT: "api/courses/get-course-by-student",
  },

  EDIT_EVENT: "api/events/speakers/edit-event",
  REMOVE_EVENT_IMAGE: "api/events/speakers/remove-event-image",
  SEARCH_EVENT: "api/events/search-event",
  GET_ONGOING_EVENTS: "api/events/ongoing-event",
  GET_ALL_PENDING_EVENTS: "api/events/get-all-pending-events",
  GET_EVENTS_BY_SPEAKER: "api/events/speakers/events",
  ACCEPT_PENDING_EVENT: "api/events/accept-pending-event",
  GET_EVENT: "api/events/get-event",
  REFRESH_TOKEN: "api/all/refresh-token/refresh",

  ADD_CATEGORY: "api/category/add-category",
  GET_ALL_CATEGORY: "api/category/get-all-categories",
  GET_CATEGORY_BY_ID: "api/category/get-category",
  EDIT_CATEGORY: "api/category/edit-category",
  GET_RECOMMENDED_EVENTS: "api/events/get-recommended-events",
  GET_TRENDING_EVENTS: "api/events/get-trending-events",
  CHANGE_PASSWORD: "api/students/change-password",
  UPDATE_PROFILE: "api/students/update-profile",
  GET_STUDENT_DETAILS: "api/students/get-student-details",
  GET_EVENT_BY_STUDENT: "api/events/get-event-by-student",
  GET_ALL_STUDENTS: "api/students/get-all-students",
  BLOCK_STUDENT: "api/students/block-student",
  UNBLOCK_STUDENT: "api/students/unblock-student",
  GET_BLOCKED_STUDENTS: "api/students/get-all-blocked-students",
  ADMIN_DASHBOARD_DATA: "api/admin/dashboard-details",
  GET_GRAPH_DATA_ADMIN: "api/admin/graph-data",
  INSTRUCTOR_CHANGE_PASSWORD: "api/instructors/change-password",
  INSTRUCTOR_UPDATE_PROFILE: "api/instructors/update-profile",
  GET_MY_STUDENTS: "api/instructors/get-students-by-instructor",
  GET_INSTRUCTOR_DETAILS: "api/instructors/get-instructor-details",

  STUDENT_CONTACT_US: "api/students/contact-us",
  CONTACT_US: "api/contact_us",
  ABOUT_OWNER: "api/about-owner",
  SHOW_GALLERY: "api/show-gallery",
};
export default END_POINTS;
