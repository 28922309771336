import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EventInterface } from "../../types/event"; // Corrected import path

interface InitialState {
  event: EventInterface | null;
}

const initialState: InitialState = {
  event: null,
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEvent(state, action: PayloadAction<{ event: EventInterface }>) {
      state.event = action.payload.event;
    },
    clearEvent(state) {
      state.event = null;
    },
  },
});

export const { setEvent, clearEvent } = eventSlice.actions;

export const selectEvent = (state: RootState) => state.event.event

export const eventReducer = eventSlice.reducer;
