import { PaymentIntent } from "@stripe/stripe-js";
import END_POINTS from "../../../constants/endpoints";
import {
  addCourseService,
  editCourseService,
  removeCourseImageService,
  getAllCoursesService,
  acceptPendingCourseService,
  getIndividualCourseService,
  getRecommendedCoursesService,
  getTrendingCoursesService,
  getCourseByStudentService,
  searchCourseService,
  removeCourseService,
} from "api/services/event/course-service";
import { enrollStudentService } from "api/services/event/event-service";

export const addCourse = (courseInfo: FormData) => {
  return addCourseService(END_POINTS.COURSES.ADD_COURSE, courseInfo);
};

export const editCourse = (courseId: string, courseInfo: FormData) => {
  return editCourseService(
    END_POINTS.COURSES.EDIT_COURSE,
    courseId,
    courseInfo
  );
};
export const removeCourse = (courseId: string) => {
  return removeCourseService(END_POINTS.COURSES.REMOVE_COURSE, courseId);
};

export const removeEventImage = (courseId: string, imageId: string) => {
  return removeCourseImageService(
    END_POINTS.COURSES.REMOVE_COURSE_IMAGE,
    courseId,
    imageId
  );
};

export const getAllCourses = () => {
  return getAllCoursesService(END_POINTS.COURSES.GET_ALL_COURSES);
};

export const getAllPendingCourses = () => {
  return getAllCoursesService(END_POINTS.COURSES.GET_ALL_PENDING_COURSES);
};

export const acceptPendingCourse = (courseId: string) => {
  return acceptPendingCourseService(
    END_POINTS.COURSES.ACCEPT_PENDING_COURSE,
    courseId
  );
};

export const getIndividualCourse = (courseId: string) => {
  return getIndividualCourseService(END_POINTS.COURSES.GET_COURSE, courseId);
};

export const enrollStudent = (
  courseId: string,
  paymentInfo?: PaymentIntent
) => {
  return enrollStudentService(
    END_POINTS.COURSES.ENROLL_STUDENT,
    courseId,
    paymentInfo
  );
};

export const getRecommendedCourses = () => {
  return getRecommendedCoursesService(
    END_POINTS.COURSES.GET_RECOMMENDED_COURSES
  );
};

export const getTrendingCourses = () => {
  return getTrendingCoursesService(END_POINTS.COURSES.GET_TRENDING_COURSES);
};

export const getCourseByStudent = () => {
  return getCourseByStudentService(END_POINTS.COURSES.GET_COURSE_BY_STUDENT);
};

export const searchCourse = (searchQuery: string, filterQuery: string) => {
  return searchCourseService(
    END_POINTS.COURSES.SEARCH_COURSE,
    searchQuery,
    filterQuery
  );
};
