import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "@material-tailwind/react";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { APP_LOGO } from "../../constants/common";
import {
  selectIsLoggedIn,
  selectUserType,
} from "../../redux/reducers/authSlice";
import ProfileMenu from "../elements/profile-menu";
import Container from "components/common/container";

const navigation = [
  { name: "Home", href: "/", current: false },
  { name: "Events", href: "/events", current: false },
  { name: "Speakers", href: "/speakers", current: false },
  { name: "Contact Us", href: "/contact", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const StudentHeader: React.FC = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUserType);
  const { pathname } = useLocation();

  const handleNavigation = (item: any) => {
    navigation.forEach((navItem) => {
      navItem.current = navItem.href === item.href;
    });
  };

  return (
    <div className="bg-gray-100 border-b border-b-gray-300 w-full flex justify-center">
      <Disclosure
        as="nav"
        className="bg-white w-[100%] md:pr-8 nd:py-2 md:py-2 lg:py-3"
      >
        {({ open }) => (
          <Container isCenterable={false}>
          <div className="">
            <div className="max-w-full md:w-full lg:w-full">
              <div className="flex items-center justify-between md:h-14 lg:h-16 ">
                <div className="">
                  <Link to="/">
                    <img className="h-20" src={APP_LOGO} alt="Your Company" />
                  </Link>
                </div>

                <div className="flex items-center justify-between gap-5 md:h-14 lg:h-16 ">
                  <div className="flex items-center">
                    <div className="hidden lg:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            onClick={() => handleNavigation(item)}
                            className={classNames(
                              item.current || pathname === item.href
                                ? "underline underline-offset-8 decoration-orangeCustom text-black font-semibold"
                                : "text-black hover:text-blue-gray-800 hover:underline  underline-offset-8  hover:bg-transparent hover:decoration-orangeLightCustom ",
                              "rounded-md px-3 py-2 text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                  {isLoggedIn  ? (
                    <div className="hidden md:ml-5 md:flex items-center justify-between">
                      <div className="">
                        <Link to={user?.includes("admin") ? "/admin/speakers" :"/dashboard"}>
                          <Button
                            size="md"
                            className="bg-orangeCustom hover:bg-orangeLightCustom"
                            placeholder={undefined}
                          >
                            Dashboard
                          </Button>
                        </Link>
                      </div>
                      <div className=" pl-3 ml-3 items-end">
                        <ProfileMenu />
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <div className="hidden h-8 lg:mt-3 lg:h-12  lg:flex">
                        <div className="space-x-4 flex w-full border border-green-200">
                          {/* <Link to="/admin">
                            <Button
                              variant="filled"
                              placeholder={""}
                              className="bg-orangeCustom rounded-none hover:shadow-orangeLightCustom hover:shadow-md"
                            >
                              Admin Login
                            </Button>
                          </Link> */}
                          <Link to="/login" className="w-40">
                            <Button
                              variant="filled"
                              placeholder={""} 
                              className="bg-orangeCustom rounded-none hover:shadow-orangeLightCustom hover:shadow-md w-full h-full"
                            >
                              Student Login
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="md:-mr-2 flex lg:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel>
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => handleNavigation(item)}
                    className={classNames(
                      item.current
                        ? "underline underline-offset-8 decoration-orangeCustom text-black font-semibold"
                        : "text-black hover:text-blue-gray-800 underline-offset-8  hover:bg-lightOrangeCustom ",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="mt-3 space-y-1 px-2">
                  <Link to="/admin">
                    <button className="w-full mb-2 block px-3 py-2 text-white font-medium  bg-orangeCustom rounded-none hover:shadow-orangeLightCustom hover:shadow-md">
                      Login
                    </button>
                  </Link>
                </div>
              </div>
            </Disclosure.Panel>
          </div>
          </Container>
        )}
      </Disclosure>
    </div>
  );
};

export default StudentHeader;
